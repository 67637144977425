import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      _id: null,
      idToken: null,
      avatar: null,
      fullname: null,
      email: null,
      dept: null,
      role: null,
      token: null,
      userType: null,
      sessId: null,
      permissions: null
    }
  },
  getters: {
    user (state) {
      return state.user
    }
  },
  mutations: {
    login (state, payload) {
      state.user = {
        _id: payload._id,
        idToken: payload.idToken,
        avatar: payload.avatar,
        fullname: payload.fullname,
        email: payload.email,
        dept: payload.dept,
        role: (typeof payload.role === 'undefined') ? null : payload.role,
        token: payload.token,
        userType: payload.userType,
        sessId: payload.sessId,
        permissions: payload.permissions
      }
    },
    logout (state) {
      state.user = {
        _id: null,
        idToken: null,
        avatar: null,
        fullname: null,
        email: null,
        dept: null,
        role: null,
        token: null,
        userType: null,
        sessId: null,
        permissions: null
      }
      state.carts = []
    },
    updateProfile (state, payload) {
      state.user.dept = payload.dept
    }
  },
  actions: {
    login (context, payload) {
      context.commit('login', payload)
    },
    logout (context) {
      context.commit('logout')
    },
    updateProfile (context, payload) {
      context.commit('updateProfile', payload)
    }
  },
  modules: {
  }
})
